<template>
  <div>


    <!-- basic modal -->
    <b-modal id="modal-1" title="Upload Dokumen" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <b-card-text>
        <h5></h5>
        <div>
          <label for="textarea-default">Periode Tahun Dokumen</label>
          <b-form-select v-model="selected" :options="options" size="md" />
        </div>

        <b-form-group label="Judul Dokumen" label-for="basicInput">
          <b-form-input id="basicInput" placeholder="Enter Title Document" />
        </b-form-group>
        <div>
          <label for="textarea-default">Upload Dokumen</label>
          <b-form-file v-model="file" placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..." />
        </div>


        <div>
          <label for="textarea-default">Keterangan</label>
          <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" />
        </div>

      </b-card-text>
      <hr>
      <div class="row">
        <div class="col-md-6"><button type="button" class="btn btn-primary btn-sm"><svg
              xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-save">
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg> Simpan </button><button type="button" class="btn ml-1 btn-warning btn-sm" @click="close"><svg
              xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-refresh-ccw">
              <polyline points="1 4 1 10 7 10"></polyline>
              <polyline points="23 20 23 14 17 14"></polyline>
              <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
            </svg> Batal </button></div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BCardText, BFormSelect, BFormFile } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BCardText, BFormSelect, BFormFile
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      options: [
        { value: 'a', text: '2023' },
        { value: 'b', text: '2024' },
      ], file: null,
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>