<template>
  <div>
    <div class="card">
      <div class="card-body">
        <HeaderData></HeaderData>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <tabel-data></tabel-data>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderData from './component/header.vue';
import tabelData from './component/TabelData.vue';
export default {
  components: { tabelData, HeaderData },

}
</script>

<style></style>