<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>Periode Renstra</label>
          <v-select v-model="id_balai_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
            :options="balai_bidang" />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group>
          <label>Tujuan</label>
          <v-select v-model="id_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_program"
            :options="program" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button variant="info" class="btn-icon mt-2" block @click="loadAll()">
          <feather-icon icon="RefreshCcwIcon" size='15' /> Tampilkan Semua Sub Kegiatan
        </b-button>
      </b-col>
      <b-col md="8">
        <b-form-group>
          <label>Sasaran</label>
          <v-select v-model="id_subkegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_pekerjaan"
            :options="kegiatan" />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BAvatar, BAlert, BButton
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAvatar, BAlert, vSelect, BButton
  },
  data() {
    return {
      id_balai_bidang: {}, balai_bidang: [
        { value: '', nama: "2023-2025" },
        { value: '2', nama: "2024-2026" }
      ],
      id_kegiatan: {},
      id_subkegiatan: {},
      program: [
        {
          "id_program": "1",
          "nama_program": "Meningkatkan Kualitas Jalan Provinsi"
        },
        {
          "id_program": "2",
          "nama_program": "Meningkatkan Kualitas Pelaksanaan Reformasi Birokrasi di Perangkat Daerah"
        }, {
          "id_program": "3",
          "nama_program": "Meningkatkan Kualitas Pelayanan Keciptakaryaan (IRB)"
        }, {
          "id_program": "4",
          "nama_program": "Meningkatkan Kualitas Pelayanan Keciptakaryaan (LH)"
        }, {
          "id_program": "5",
          "nama_program": "Meningkatkan Kualitas Pelayanan Keciptakaryaan (PE)"
        },
      ],
      kegiatan: [
        {
          "id_pekerjaan": "1",
          "nama_pekerjaan": "Meningkatnya Panjang Jalan Provinsi dengan Kualitas Struktur dan Kapasitas Sesuai Standar Jalan Kolektor",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "2",
          "nama_pekerjaan": "Terjaganya Kondisi Permukaan Jalan Mantap",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "3",
          "nama_pekerjaan": "Meningkatnya Kualitas Pelayanan Perangkat Daerah",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "4",
          "nama_pekerjaan": "Meningkatnya Kualitas Pengelolaan Risiko Perangkat Daerah",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "5",
          "nama_pekerjaan": "Meningkatnya Kualitas Hasil Penyelenggaraan Pembangunan Bangunan Gedung Milik Daerah ",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "6",
          "nama_pekerjaan": "Meningkatnya Akses Air Minum Aman",
          "id_kategori": "1"
        },
        {
          "id_pekerjaan": "7",
          "nama_pekerjaan": "Meningkatnya Akses Air Limbah Domestik Aman",
          "id_kategori": null
        }
      ]
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['LoadProgram', 'LoadKegiata', 'GetLoadData', 'loadAll'],
  methods: {
    LoadKegiata() {
      this.id_subkegiatan = {},
        this.$emit("LoadKegiata", this.id_kegiatan.id_program, this.id_balai_bidang.id_bidang)
    },
    loadAll() {
      this.$emit('loadAll');
    },
    LoadProgram(val) {
      this.id_kegiatan = {};
      this.id_sub_kegiatan = {};
      this.$emit("LoadProgram", val.id_bidang)
    },
    GetLoadData() {
      this.$emit("GetLoadData", this.id_kegiatan.id_program, this.id_balai_bidang.id_bidang, this.id_subkegiatan.id_sub_kegiatan)
    }

  },
  props: {




  },
  computed: {
    setbalai_bidang: {
      get: function () {
        return this.balai_bidang;
      },
      set: function (newValue) {
        return this.balai_bidang = newValue;
      }
    },
    setProgram: {
      get: function () {
        return this.program;
      },
      set: function (newValue) {
        return this.program = newValue;
      }
    },
    setKegiatan: {
      get: function () {
        return this.kegiatan;
      },
      set: function (newValue) {
        return this.kegiatan = newValue;
      }
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>