<template>
  <b-row>
    <b-col md="3" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">

    </b-col>
    <b-col md="5" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>


    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="9">
              <small class="text-primary">Judu Dokumen</small>
              <h5 class="text-primary"><strong>{{ data.item.bpj }}</strong></h5>
              <h6 class="text-warning"> <small><strong>Keterangan</strong> </small></h6>
              <small style="text-align: center;">{{ data.item.keterangan }}</small>
            </b-col>

            <b-col md="3">
              <a :href="data.item.link" class="mb-2" download="" target="_blank">
                <b-button block variant="outline-info" size="sm" class="mb-1">
                  <feather-icon icon="DownloadIcon" size="15"></feather-icon> Downoad File
                </b-button>
              </a>
              <b-row>
                <b-col md="6">
                  <b-button block variant="warning" size="sm">
                    <feather-icon icon="Edit3Icon" size="15"></feather-icon>
                  </b-button>
                </b-col>
                <b-col md="6">
                  <b-button block variant="danger" size="sm">
                    <feather-icon icon="TrashIcon" size="15"></feather-icon>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [{
        id: 1,
        bpj: "Renstra Bina Marga 2015-2019",
        link: 'https://binamarga.pu.go.id/assets/files/LAKIP_dan_Renstra/Renstra_DJBM_2015-2019.pdf',
        keterangan: "Rencana Strategis Kementerian Pekerjaan Umum dan Perumahan Rakyat Tahun 2020-2024, Renstra Bina Marga 2015-2019"

      }, {
        id: 1,
        bpj: "Review Renstra Bina Marga 2015-2019",
        link: 'https://binamarga.pu.go.id/assets/files/LAKIP_dan_Renstra/Review_Rencana_Strategis_bina_marga_2015-2019_optimized.pdf',
        keterangan: "Rencana Strategis Kementerian Pekerjaan Umum dan Perumahan Rakyat Tahun 2020-2024, Review Renstra Bina Marga 2015-2019"

      }, {
        id: 1,
        bpj: "Renstra Kementerian PUPR 2020-2024 ",
        link: 'https://binamarga.pu.go.id/assets/files/LAKIP_dan_Renstra/Review_Rencana_Strategis_bina_marga_2015-2019_optimized.pdf',
        keterangan: "Rencana Strategis Kementerian Pekerjaan Umum dan Perumahan Rakyat Tahun 2020-2024, Renstra Kementerian PUPR 2020-2024"

      },



      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>