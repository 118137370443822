<template>
  <div>
    <!-- modal trigger button -->

    <b-modal id="modal-1" size="xl" title="Basic Modal" ok-disabled no-close-on-backdrop hide-footer>
      <b-card-text>
        <div id="modal-1___BV_modal_body_" class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <h6><small class="text-primary">Tujuan </small><br><small> Meningkatkan Kualitas Jalan Provinsi
                      <br><small class="text-primary">Indikator Tujuan </small><br> Persentase jalan provinsi sesuai
                      standar jalan
                      kolektor dan kondisi permukaan mantap </small></h6>
                </div>
                <div class="col-md-6">
                  <h6><small class="text-primary">Sasaran </small><br><small> Terjaganya Kondisi Permukaan Jalan
                      Mantap<br><small class="text-primary">Indikator Sasaran </small><br> Persentase kondisi jembatan
                      provinsi baik </small></h6>
                </div>
              </div>


              <hr>
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-2">
                      <h6 class="text-primary"><small><strong> <b>01.03.10</b></strong></small></h6>
                    </div>
                    <div class="col-md-10">
                      <h6 class="text-primary"><small> <b>PROGRAM PENYELENGGARAAN JALAN</b></small></h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">

                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning"><!---->
                    <div class="alert-body">
                      <h6 class="text-warning"><strong>Tahun 2024</strong></h6>
                      <h6><small>Target : <strong> 83.65%</strong></small></h6>
                      <h6><small><strong>Rp. 3.874.160.000</strong></small></h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning"><!---->
                    <div class="alert-body">
                      <h6 class="text-warning"><strong>Tahun 2025</strong></h6>
                      <h6><small>Target : <strong> 83.7%</strong></small></h6>
                      <h6><small><strong>Rp. 1.539.746.000</strong></small></h6>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-warning"><!---->
                    <div class="alert-body">
                      <h6 class="text-warning"><strong>Tahun 2026</strong></h6>
                      <h6><small>Target : <strong> 83.75%</strong></small></h6>
                      <h6><small><strong>Rp. 1.767.361.000</strong></small></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5><strong><feather-icon icon="GridIcon" size='15' style="margin-bottom: 3px;" /> Daftar Sub
              kegiatan</strong></h5>
          <tabel-sub-kegiatan></tabel-sub-kegiatan>
        </div>

      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TabelSubKegiatan from './TabelSubKegiatan.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    TabelSubKegiatan,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>