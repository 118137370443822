<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" class="my-1">
      <b-button variant="info" block size="sm">
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Indikator Sasaran Renstra
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="5">
              <h5 class="text-primary">{{ data.item.indikator_program }}</h5>
              <h6 class="text-primary">
                {{ data.item.kode_program }} {{ data.item.nama_progream }}
              </h6>
              <h6><small><b>Keterangan :</b> {{ data.item.keterangan }}</small> </h6>
            </b-col>
            <b-col md="7">
              <b-row>
                <b-col md="12">
                  <h6> <strong> Capaian Kinerja Program dan Kerangka
                      Pendanaan</strong> </h6>
                  <b-row>
                    <b-col md="4">
                      <b-alert variant="warning" show>
                        <div class="alert-body">
                          <h6 class="text-warning"><strong>Tahun 2024</strong></h6>
                          <h6><small>Target : <strong> {{ data.item.capaian_t1.target }}</strong> </small>
                          </h6>
                          <h6><small><strong>Rp. {{ data.item.capaian_t1.nominal }}</strong> </small></h6>
                        </div>
                      </b-alert>

                    </b-col>
                    <b-col md="4">
                      <b-alert variant="warning" show>
                        <div class="alert-body">
                          <h6 class="text-warning"><strong>Tahun 2025</strong></h6>
                          <h6><small>Target : <strong> {{ data.item.capaian_t2.target }}</strong> </small>
                          </h6>
                          <h6><small><strong>Rp. {{ data.item.capaian_t2.nominal }}</strong> </small></h6>
                        </div>
                      </b-alert>
                    </b-col>
                    <b-col md="4">
                      <b-alert variant="warning" show>
                        <div class="alert-body">
                          <h6 class="text-warning"><strong>Tahun 2026</strong></h6>
                          <h6><small>Target : <strong> {{ data.item.capaian_t3.target }}</strong> </small>
                          </h6>
                          <h6><small><strong>Rp. {{ data.item.capaian_t3.nominal }}</strong> </small></h6>
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <b-button variant="outline-primary" block size="sm" v-b-modal.modal-1
                    @click="DetailKegiatan(data.item)">
                    <feather-icon icon="GridIcon" size='15' /> Detail Renstra Pada Sub Kegiatan
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
    <detail-resntra-sub-kegiatan></detail-resntra-sub-kegiatan>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert
} from 'bootstrap-vue'
import DetailResntraSubKegiatan from './DetailResntraSubKegiatan.vue'

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    DetailResntraSubKegiatan,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 1,
          kode_program: "1.0 3.1 0",
          nama_progream: "PROGRAM PENYELEN GGARAAN JALAN **",
          indikator_program: 'Persentase panjang jembatan provinsi kondisi  baik  di BPJ  Wilayah Cilacap  **',
          capaian_t1: {
            target: '83.65%',
            nominal: '3.874.160.000',
          },
          capaian_t2: {
            target: '83.7%',
            nominal: '1.539.746.000',
          },
          capaian_t3: {
            target: '83.75%',
            nominal: '1.767.361.000',
          },
          keterangan: "Balai Pengelolaan Jalan Wilayah Cilacap"

        },
        {
          id: 2,
          kode_program: "1.0 3.1 0",
          nama_progream: "PROGRAM PENYELEN GGARAAN JALAN **",
          indikator_program: 'Persentase panjang jembatan provinsi kondisi  baik  di BPJ  Wilayah Magelang  **',
          capaian_t1: {
            target: '93.65%',
            nominal: '1.082.400.000',
          },
          capaian_t2: {
            target: '93.7%',
            nominal: '1.843.150.000',
          },
          capaian_t3: {
            target: '93.75%',
            nominal: '2.945.256.000',
          },
          keterangan: "Balai Pengelolaan Jalan Wilayah Magelang"

        },

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>