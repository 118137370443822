<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" class="my-1">
      <b-button variant="info" block size="sm">
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Ruas Jalan
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <div class="row">
            <div class="col-md-5">
              <h6 class="text-primary"></h6><small class="text-primary">NamaRuas Jalan</small>
              <h6 class="text-primary"><strong> </strong>{{ data.item.nama_jalan }} <small>(Kode Ruas {{
                data.item.kode_ruas }})</small>
              </h6>
              <h5 class="mtm text-primary"><span class="text-primary"><small>Panjang Ruas Jalan </small><b> {{
                data.item.panjang_ruas }} {{ data.item.satuan }} </b></span>
                <br><small>Keterangan : {{ data.item.keterangan }}</small>
              </h5>


            </div>
            <div class="col-md-5">
              <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary"><!---->
                <div class="alert-body">
                  <h6><small><strong>INDIKASI PENANGANAN</strong></small></h6>
                  <div class="row">
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2020-2024</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t1 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2025-2029</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t2 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2030-2034</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t3 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2035-2039</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t4 == '1'"> </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary">
                <feather-icon icon="GridIcon" size='15' /> Detail Kegiatan </b-button>


              <b-row class="mt-1">
                <b-col md="6">
                  <b-badge variant="warning" class="btn-icon btn-sm d-block">
                    <feather-icon icon="EditIcon" size='15' /> edit
                  </b-badge>
                </b-col>
                <b-col md="6">
                  <b-badge variant="danger" class="btn-icon btn-sm d-block">
                    <feather-icon icon="TrashIcon" size='15' /> hapus
                  </b-badge>
                </b-col>

              </b-row>
            </div>

          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 50,
          kode_ruas: "1-D-1",
          nama_jalan: "FLYOVER ARTERI (SIMPANG MADUKORO)",
          panjang_ruas: "221,40",
          satuan: "M",
          keterangan: "Dukungan KSPN",
          ts: {
            t1: "1",
            t2: "",
            t3: "",
            t4: ""
          }
        },
        {
          id: 51,
          kode_ruas: "2-D-2",
          nama_jalan: "UNDERPASS JOGLO",
          panjang_ruas: "450,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "1",
            t2: "",
            t3: "",
            t4: ""
          }
        },
        {
          id: 52,
          kode_ruas: "3-D-3",
          nama_jalan: "FLYOVER NOTOG (BANYUMAS)",
          panjang_ruas: "445,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "1",
            t3: "",
            t4: ""
          }
        },
        {
          id: 53,
          kode_ruas: "4-D-4",
          nama_jalan: "FLYOVER KARANGANYAR (KEBUMEN)",
          panjang_ruas: "361,50",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "1",
            t3: "",
            t4: ""
          }
        },
        {
          id: 54,
          kode_ruas: "5-D-5",
          nama_jalan: "FLYOVER KUTOWINANGUN (KEBUMEN)",
          panjang_ruas: "361,60",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "1",
            t3: "",
            t4: ""
          }
        },
        {
          id: 55,
          kode_ruas: "6-D-6",
          nama_jalan: "FLYOVER CANGUK (MAGELANG)",
          panjang_ruas: "166,00",
          satuan: "M",
          keterangan: "Dukungan KSPN",
          ts: {
            t1: "1",
            t2: "",
            t3: "",
            t4: ""
          }
        },
        {
          id: 56,
          kode_ruas: "7-D-7",
          nama_jalan: "FLYOVER KARTINI (KLATEN)",
          panjang_ruas: "350,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "1",
            t3: "",
            t4: ""
          }
        },
        {
          id: 57,
          kode_ruas: "8-D-8",
          nama_jalan: "FLYOVER DIPONEGORO (KLATEN)",
          panjang_ruas: "350,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "1",
            t3: "",
            t4: ""
          }
        },
        {
          id: 58,
          kode_ruas: "9-D-9",
          nama_jalan: "FLYOVER KARTOSURO",
          panjang_ruas: "450,00",
          satuan: "M",
          keterangan: "Simpang Tiga Jalan Nasional",
          ts: {
            t1: "",
            t2: "1",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 59,
          kode_ruas: "10-D-10",
          nama_jalan: "JLN. KALIGAWE (SEMARANG)",
          panjang_ruas: "440,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 60,
          kode_ruas: "11-D-11",
          nama_jalan: "JLN. SLAMET (PEKALONGAN)",
          panjang_ruas: "350,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 61,
          kode_ruas: "12-D-12",
          nama_jalan: "JLN. KH. MAS MANSYUR (PEKALONGAN)",
          panjang_ruas: "500,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 62,
          kode_ruas: "13-D-13",
          nama_jalan: "JLN. AR HAKIM (TEGAL)",
          panjang_ruas: "350,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 63,
          kode_ruas: "14-D-14",
          nama_jalan: "PEJAGAN - SP. TIGA TOL PEJAGAN KANCI",
          panjang_ruas: "600,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 64,
          kode_ruas: "15-D-15",
          nama_jalan: "JLN. VETERAN (PURWOKERTO)",
          panjang_ruas: "600,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 65,
          kode_ruas: "16-D-16",
          nama_jalan: "SAMPANG - BUNTU (RANDEGAN)",
          panjang_ruas: "440,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "1",
            t4: ""
          }
        },
        {
          id: 66,
          kode_ruas: "17-D-17",
          nama_jalan: "SIDAREJA – SP. 3 JERUK LEGI (KUBANGKANGKUNG)",
          panjang_ruas: "456,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "",
            t4: "1"
          }
        },
        {
          id: 67,
          kode_ruas: "18-D-18",
          nama_jalan: "SP. 3 JERUK LEGI – BTS. KOTA CILACAP (JERUK LEGI)",
          panjang_ruas: "440,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "",
            t4: "1"
          }
        },
        {
          id: 68,
          kode_ruas: "19-D-19",
          nama_jalan: "JLN. TENTARA PELAJAR (CILACAP) (GUMILIR)",
          panjang_ruas: "700,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "",
            t4: "1"
          }
        },
        {
          id: 69,
          kode_ruas: "20-D-20",
          nama_jalan: "KESUGIHAN – MAOS – SAMPANG (MAOS)",
          panjang_ruas: "700,00",
          satuan: "M",
          keterangan: "Perlintasan Sebidang Kereta Api",
          ts: {
            t1: "",
            t2: "",
            t3: "",
            t4: "1"
          }
        }
      ]

      ,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>