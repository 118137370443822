<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" class="my-1">
      <b-button variant="info" block size="sm">
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Ruas Jalan
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <div class="row">
            <div class="col-md-5">
              <h6 class="text-primary"></h6><small class="text-primary">NamaRuas Jalan</small>
              <h6 class="text-primary"><strong> </strong>{{ data.item.nama_jalan }} <small>(Kode Ruas {{
                data.item.kode_ruas }})</small>
              </h6>
              <h5 class="mtm text-primary"><span class="text-primary"><small>Panjang Ruas Jalan </small><b> {{
                data.item.panjang_ruas }} {{ data.item.satuan }} </b></span>
                <br><small>Keterangan : {{ data.item.keterangan }}</small>
              </h5>


            </div>
            <div class="col-md-5">
              <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary"><!---->
                <div class="alert-body">
                  <h6><small><strong>INDIKASI PENANGANAN</strong></small></h6>
                  <div class="row">
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2020-2024</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t1 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2025-2029</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t2 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2030-2034</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t3 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2035-2039</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t4 == '1'"> </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary">
                <feather-icon icon="GridIcon" size='15' />Detail Kegiatan
              </b-button>


              <b-row class="mt-1">
                <b-col md="6">
                  <b-badge variant="warning" class="btn-icon btn-sm d-block">
                    <feather-icon icon="EditIcon" size='15' /> edit
                  </b-badge>
                </b-col>
                <b-col md="6">
                  <b-badge variant="danger" class="btn-icon btn-sm d-block">
                    <feather-icon icon="TrashIcon" size='15' /> hapus
                  </b-badge>
                </b-col>

              </b-row>
            </div>

          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 25,
          kode_ruas: "1-B-1",
          nama_jalan: "AYAH - JLADRI",
          panjang_ruas: "17,00",
          satuan: "KM",
          keterangan: "Konektivitas Pansela",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 26,
          kode_ruas: "2-B-2",
          nama_jalan: "SLARANG - ADIPALA",
          panjang_ruas: "5,60",
          satuan: "KM",
          keterangan: "Konektivitas Pansela",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 27,
          kode_ruas: "3-B-3",
          nama_jalan: "LINGKAR KERTEK (WONOSOBO)",
          panjang_ruas: "16,30",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 28,
          kode_ruas: "4-B-4",
          nama_jalan: "LINGKAR KRETEK BUMIAYU (KAB.BREBES)",
          panjang_ruas: "4,40",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 29,
          kode_ruas: "5-B-5",
          nama_jalan: "LINGKAR PARAKAN (TEMANGGUNG)",
          panjang_ruas: "10,18",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar",
          ts: {
            t1: '',
            t2: '1',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 30,
          kode_ruas: "6-B-6",
          nama_jalan: "SEMARANG OUTER RING ROAD (SORR)",
          panjang_ruas: "66,16",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar Kota",
          ts: {
            t1: '',
            t2: '1',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 31,
          kode_ruas: "7-B-7",
          nama_jalan: "LINGKAR REMBANG - LASEM",
          panjang_ruas: "25,50",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar",
          ts: {
            t1: '',
            t2: '',
            t3: '',
            t4: '1'
          },
        },
        {
          id: 32,
          kode_ruas: "8-B-8",
          nama_jalan: "LINGKAR PETANGLONG",
          panjang_ruas: "25,20",
          satuan: "KM",
          keterangan: "Konektivitas Lingkar (pekalongan)",
          ts: {
            t1: '1',
            t2: '',
            t3: '',
            t4: ''
          },
        },
        {
          id: 33,
          kode_ruas: "9-B-9",
          nama_jalan: "BREBES -TEGAL (AKSES KI BREBES)",
          panjang_ruas: "5,50",
          satuan: "KM",
          keterangan: "Akses Kawasan Industri, belum ada masterplan KI",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 34,
          kode_ruas: "10-C-1",
          nama_jalan: "BTS. JABAR - PATIMUAN - TAMBAKREJA",
          panjang_ruas: "4,60",
          satuan: "KM",
          keterangan: "Konektivitas Pansela",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 35,
          kode_ruas: "11-C-2",
          nama_jalan: "ADIPALA - AYAH",
          panjang_ruas: "26,94",
          satuan: "KM",
          keterangan: "Konektivitas Pansela",
          ts: {
            t1: '',
            t2: '1',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 36,
          kode_ruas: "12-C-3",
          nama_jalan: "SURAKARTA - GEMOLONG - GEYER / BTS. KAB. GROBOGAN (AKSES KSN/KSPN SANGIRAN)",
          panjang_ruas: "34,63",
          satuan: "KM",
          keterangan: "Akses KSN Sangiran",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 37,
          kode_ruas: "13-C-4",
          nama_jalan: "AKSES JALAN PELABUHAN PENYEBERANGAN JEPARA",
          panjang_ruas: "2,35",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Pelabuhan Penyeberangan)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 38,
          kode_ruas: "14-C-5",
          nama_jalan: "AKSES JALAN PELABUHAN PENYEBERANGAN KENDAL",
          panjang_ruas: "4,25",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Pelabuhan Penyeberangan)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 39,
          kode_ruas: "15-C-6",
          nama_jalan: "AKSES TERMINAL TIPE A CEPU (BLORA)",
          panjang_ruas: "5,48",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Terminal Tipe A)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 40,
          kode_ruas: "16-C-7",
          nama_jalan: "AKSES TERMINAL TIPE A GIRI ADIPURA (WONOGIRI)",
          panjang_ruas: "29,93",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Terminal Tipe A)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 41,
          kode_ruas: "17-C-8",
          nama_jalan: "AKSES TERMINAL TIPE A PURWOKERTO (PURWOKERTO)",
          panjang_ruas: "0,75",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Terminal Tipe A)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 42,
          kode_ruas: "18-C-9",
          nama_jalan: "AKSES TERMINAL TIPE A BOBOTSARI (PURBALINGGA)",
          panjang_ruas: "30,18",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Terminal Tipe A)",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 43,
          kode_ruas: "19-C-10",
          nama_jalan: "AKSES KSPN DIENG",
          panjang_ruas: "8,89",
          satuan: "KM",
          keterangan: "Akses KSPN",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 44,
          kode_ruas: "20-C-11",
          nama_jalan: "AKSES JALAN PELABUHAN BATANG",
          panjang_ruas: "5,12",
          satuan: "KM",
          keterangan: "Akses Simpul Transportasi (Pelabuhan Laut)",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 45,
          kode_ruas: "21-C-12",
          nama_jalan: "BEDAH - MENOREH (NGLAMBUR - JL. BADRAWATI)",
          panjang_ruas: "7,51",
          satuan: "KM",
          keterangan: "Lanjutan Akses KSPN Borobudur dari D.I.Yogyakarta",
          ts: {
            t1: '',
            t2: '1',
            t3: '',
            t4: ''
          },
        },
        {
          id: 46,
          kode_ruas: "22-C-13",
          nama_jalan: "KUDUS - KLAMBU",
          panjang_ruas: "20,15",
          satuan: "KM",
          keterangan: "Penghubung Lintas",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 47,
          kode_ruas: "23-C-14",
          nama_jalan: "KLAMBU - GODONG - PURWODADI",
          panjang_ruas: "25,19",
          satuan: "KM",
          keterangan: "Penghubung Lintas",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 48,
          kode_ruas: "24-C-15",
          nama_jalan: "PURWODADI - GEYER",
          panjang_ruas: "18,31",
          satuan: "KM",
          keterangan: "Penghubung Lintas",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        },
        {
          id: 49,
          kode_ruas: "25-C-16",
          nama_jalan: "WONOSOBO - BOROBUDUR",
          panjang_ruas: "38,91",
          satuan: "KM",
          keterangan: "Penghubung Lintas",
          ts: {
            t1: '',
            t2: '',
            t3: '1',
            t4: ''
          },
        }
      ]
      ,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>