<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="3" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" class="my-1">
      <b-button variant="info" block size="sm">
        <feather-icon size='15' icon="PlusCircleIcon"></feather-icon> Tambah Ruas Jalan
      </b-button>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <div class="row">
            <div class="col-md-5">
              <h6 class="text-primary"></h6><small class="text-primary">NamaRuas Jalan</small>
              <h6 class="text-primary"><strong> </strong>{{ data.item.nama_jalan }} <small>(Kode Ruas {{
                data.item.kode_ruas }})</small>
              </h6>
              <h5 class="mtm text-primary"><span class="text-primary"><small>Panjang Ruas Jalan </small><b> {{
                data.item.panjang_ruas }} {{ data.item.satuan }} </b></span>
                <br><small>Oprasi : <b>{{ data.item.oprasi }}</b> <span v-show="data.item.oprasi != '-'">{{
                  data.item.satuan }}</span></small>
                <br><small>Rencana : <b>{{ data.item.rencana }}</b> <span v-show="data.item.rencana != '-'">{{
                  data.item.satuan }}</span></small>
              </h5>


            </div>
            <div class="col-md-5">
              <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-primary"><!---->
                <div class="alert-body">
                  <h6><small><strong>INDIKASI PENANGANAN</strong></small></h6>
                  <div class="row">
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2020-2024</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t1 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2025-2029</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t2 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2030-2034</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t3 == '1'"> </b-button>
                    </div>
                    <div xs="3" class="col-sm-3 col-md-3 col-lg-3"><small>2035-2039</small>
                      <b-button variant="success" class="btn-icon" block v-show="data.item.ts.t4 == '1'"> </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary">
                <feather-icon icon="GridIcon" size='15' /> Detail Kegiatan
              </b-button>


              <b-row class="mt-1">
                <b-col md="6">
                  <b-badge variant="warning" class="btn-icon btn-sm d-block">
                    <feather-icon icon="EditIcon" size='15' /> edit
                  </b-badge>
                </b-col>
                <b-col md="6">
                  <b-badge variant="danger" class="btn-icon btn-sm d-block">
                    <feather-icon icon="TrashIcon" size='15' /> hapus
                  </b-badge>
                </b-col>

              </b-row>
            </div>

          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 1,
          kode_ruas: "1-E-62,2",
          nama_jalan: "SLARANG - ADIPALA",
          panjang_ruas: "13,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "13.00",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 2,
          kode_ruas: "2-E-69,2",
          nama_jalan: "GEDEBAGE - TASIKMALAYA - CILACAP",
          panjang_ruas: "37,60",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "37.60",
          ts: { t1: '1', t2: '1', t3: '', t4: '' }
        },
        {
          id: 3,
          kode_ruas: "3-E-72,2",
          nama_jalan: "CIKALONG - CIPUCANG",
          panjang_ruas: "2,30",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "2.30",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 4,
          kode_ruas: "4-E-91",
          nama_jalan: "LINGKAR PARAKAN (TEMANGGUNG)",
          panjang_ruas: "57,50",
          satuan: "KM",
          keterangan: null,
          oprasi: "57.50",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 5,
          kode_ruas: "5-E-92",
          nama_jalan: "PEMALANG - BATANG",
          panjang_ruas: "39,20",
          satuan: "KM",
          keterangan: null,
          oprasi: "39.20",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 6,
          kode_ruas: "6-E-93",
          nama_jalan: "BATANG - SEMARANG",
          panjang_ruas: "75,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "75.00",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 7,
          kode_ruas: "7-E-95",
          nama_jalan: "SEMARANG - SOLO",
          panjang_ruas: "72,95",
          satuan: "KM",
          keterangan: null,
          oprasi: "72.95",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 8,
          kode_ruas: "8-E-96,1",
          nama_jalan: "SOLO - MANTINGAN - NGAWI",
          panjang_ruas: "56,12",
          satuan: "KM",
          keterangan: null,
          oprasi: "56.12",
          rencana: "-",
          ts: { t1: '1', t2: '', t3: '', t4: '' }
        },
        {
          id: 9,
          kode_ruas: "9-E-97,1",
          nama_jalan: "CILACAP - YOGYAKARTA",
          panjang_ruas: "152,95",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "152.95",
          ts: { t1: '', t2: '1', t3: '', t4: '' }
        },
        {
          id: 10,
          kode_ruas: "10-E-98,1",
          nama_jalan: "SOLO - YOGYAKARTA - NYIA KULONPROGO",
          panjang_ruas: "35,54",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "35.54",
          ts: { t1: '1', t2: '1', t3: '', t4: '' }
        },
        {
          id: 11,
          kode_ruas: "11-E-99,1",
          nama_jalan: "YOGYAKARTA - PACITAN - TRENGGALEK - LUMAJANG",
          panjang_ruas: "17,86",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "17.86",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 12,
          kode_ruas: "12-E-102",
          nama_jalan: "SEMARANG - DEMAK",
          panjang_ruas: "26,70",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "36.80",
          ts: { t1: '', t2: '', t3: '1', t4: '' }
        },
        {
          id: 13,
          kode_ruas: "13-E-103",
          nama_jalan: "DEMAK - JEPARA",
          panjang_ruas: "36,80",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "90.00",
          ts: { t1: '', t2: '1', t3: '', t4: '' }
        },
        {
          id: 14,
          kode_ruas: "14-E-104",
          nama_jalan: "DEMAK - REMBANG",
          panjang_ruas: "90,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "33.50",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 15,
          kode_ruas: "15-E-105,1",
          nama_jalan: "REMBANG-TUBAN",
          panjang_ruas: "33,50",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "38.60",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 16,
          kode_ruas: "16-E-106,1",
          nama_jalan: "BOJONEGORO - REMBANG",
          panjang_ruas: "38,60",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "141.00",
          ts: { t1: '', t2: '1', t3: '', t4: '' }
        },
        {
          id: 17,
          kode_ruas: "17-E-107",
          nama_jalan: "PEJAGAN - CILACAP",
          panjang_ruas: "141,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "81.00",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 18,
          kode_ruas: "18-E-108",
          nama_jalan: "AKSES WONOSOBO (WONOSOBO-PEKALONGAN)",
          panjang_ruas: "81,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "49.00",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 19,
          kode_ruas: "19-E-109",
          nama_jalan: "WONOSOBO-MAGELANG",
          panjang_ruas: "49,00",
          satuan: "KM",
          keterangan: null,
          oprasi: "-",
          rencana: "66.38",
          ts: { t1: '', t2: '', t3: '', t4: '1' }
        },
        {
          id: 20,
          kode_ruas: "20-E-110,1",
          nama_jalan: "YOGYAKARTA - BAWEN",
          panjang_ruas: "66,38",
          satuan: "KM",
          keterangan: null,
          oprasi: null,
          rencana: null,
          ts: { t1: '1', t2: '1', t3: '', t4: '' }
        }
      ]

      ,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>