<template>

  <div>
    <div class="content-wrapper">
      <div class="row content-header">
        <div class="content-header-left mb-2 col-md-9 col-12">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-left pr-1 mb-0"> Arsip Dokumen</h2>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/" class="router-link-active" target="_self"><svg
                        xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="align-text-top feather feather-home">
                        <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                        <polyline points="9 22 9 12 15 12 15 22"></polyline>
                      </svg></a></li>
                  <li class="breadcrumb-item"><a href="#" target="_self" class=""> Arsip </a></li>
                  <li class="breadcrumb-item active"><span aria-current="location">Dokumen</span></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-right d-md-block d-none mb-1 col-md-3 col-12">
          <button type="button" class="btn mb-75 mr-75 btn-primary btn-sm" v-b-modal.modal-1
            variant="outline-primary"><feather-icon icon="PlusIcon" size="18" class="mr-50" />
            <span class="mb-1">Upload Dokumen</span>
          </button>
          <tambah-dokumen></tambah-dokumen>
        </div>
      </div>
      <div class="content-body">
        <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left">

          <!-- general tab -->
          <b-tab active>

            <!-- title -->
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Dokumen Tahun 2023</span>
            </template>

            <div class="card"><!----><!---->
              <div class="card-body"><!----><!---->
                <tabel-data></tabel-data>
              </div><!----><!---->
            </div>
          </b-tab>
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Dokumen Tahun 2024</span>
            </template>
            <div class="card">
              <div class="card-body">
                <tabel-data-2024></tabel-data-2024>
              </div>
            </div>

          </b-tab>
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Dokumen Tahun 2025</span>
            </template>
            <div class="card">
              <div class="card-body">
                <tabel-data-2025></tabel-data-2025>
              </div>
            </div>

          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>

</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import TabelData from './component/TabelData.vue'
import TabelData2025 from './component/TabelData_2025.vue'
import TabelData2024 from './component/TabelData_2024.vue'
import TambahDokumen from './component/TambahDokumen.vue'
export default {
  components: {
    BTabs,
    BTab, TabelData,
    TabelData2025,
    TabelData2024, TambahDokumen

  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
