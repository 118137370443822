<template>
  <div>
    <b-card>

      <table width="100%" border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">
        <tr class="centerText">

          <th rowspan="3" width="30%">Uraian</th>
          <th rowspan="3" width="5%">Satuan</th>
          <th colspan="6" width="55%">Capaian Kinerja Program dan Kerangka Pendanaan</th>
          <th colspan="2" width="10%" rowspan="2">Kondisi Kinerja Pada Akhir Periode RPD</th>
        </tr>
        <tr class="centerText">
          <th colspan="2">2024</th>
          <th colspan="2">2025</th>
          <th colspan="2">2026</th>
        </tr>
        <tr class="centerText" width="100%">
          <th width="5%">Target</th>
          <th width="12%">Rp. </th>
          <th width="5%">Target</th>
          <th width="12%">Rp. </th>
          <th width="5%">Target</th>
          <th width="12%">Rp. </th>
          <th width="5%">Target</th>
          <th width="12%">Rp. </th>
        </tr>

        <tr>
          <td>
            <div>
              <small class="text-primary"><b>1.0 3.1 0.1. 01 Penyelenggaraan Jalan Provinsi ***</b> </small>
            </div>

          </td>
          <td class="text-center"><small>KM</small></td>
          <td class="text-center">203.14</td>
          <td class="floatright">23.702.376.000</td>
          <td class="text-center">205.24</td>
          <td class="floatright">19,491,806,000</td>
          <td class="text-center">198.24</td>
          <td class="floatright">30,331,172,000</td>
          <td class="text-center"> 198.24</td>
          <td class="floatright">73,525,354,000</td>
        </tr>
        <tr>
          <td>
            <div>
              <small class="ml-1"><b>1.03.10.1.01.09 Rehabilitasi Jalan****</b> </small>
            </div>

          </td>
          <td class="text-center"><small></small></td>
          <td class="text-center"></td>
          <td class="floatright">11,430,000,000</td>
          <td class="text-center"></td>
          <td class="floatright">4,996,250,000</td>
          <td class="text-center"></td>
          <td class="floatright">10,018,750,000</td>
          <td class="text-center"> </td>
          <td class="floatright"> 26,445,000,000</td>
        </tr>


        <tr>
          <td>
            <div class="pl-3">
              <small> Panjang Dinding Penahan Tanah yang Direhabilitasi di Wilayah Cilacap****
              </small>
            </div>

          </td>
          <td class="text-center"><small>KM</small></td>
          <td class="text-center">0.2</td>
          <td class="floatright">1,000,000,000</td>
          <td class="text-center">-</td>
          <td class="floatright">-</td>
          <td class="text-center">-</td>
          <td class="floatright">-</td>
          <td class="text-center">0.2</td>
          <td class="floatright">1,000,000,000</td>
        </tr>

        <tr>
          <td>
            <div class="pl-3">
              <small> Panjang Jalan yang Direhabilitasi di Wilayah Cilacap****
              </small>
            </div>

          </td>
          <td class="text-center"><small>KM</small></td>
          <td class="text-center">6.65</td>
          <td class="floatright">10,430,000,000</td>
          <td class="text-center">2.85</td>
          <td class="floatright">4,996,250,000</td>
          <td class="text-center">5</td>
          <td class="floatright">10,018,750,000</td>
          <td class="text-center"> 14.5</td>
          <td class="floatright">25,445,000,000</td>
        </tr>

        <tr>
          <td>
            <div class="pl-3">
              <small> Panjang Saluran Drainase yang Direhabilitasi di Wilayah Cilacap****
              </small>
            </div>
          </td>
          <td class="text-center"><small>KM</small></td>
          <td class="text-center">-</td>
          <td class="floatright">-</td>
          <td class="text-center">-</td>
          <td class="floatright">-</td>
          <td class="text-center">-</td>
          <td class="floatright">-</td>
          <td class="text-center"> -</td>
          <td class="floatright"> -</td>
        </tr>

        <tr>
          <td>
            <div>
              <small class="ml-1"><b>1.03.10.1.01.11 Pemeliharaan Rutin Jalan ****</b> </small>
            </div>

          </td>
          <td class="text-center"><small></small></td>
          <td class="text-center"></td>
          <td class="floatright">12,272,376,000</td>
          <td class="text-center"></td>
          <td class="floatright">14,495,556,000</td>
          <td class="text-center"></td>
          <td class="floatright">20,312,422,000</td>
          <td class="text-center"> </td>
          <td class="floatright"> 47,080,354,000</td>
        </tr>

        <tr>
          <td>
            <div class="pl-3">
              <small> Panjang Jalan yang Dilakukan PemeliharaanSeca ra Rutin di BPJ Wilayah Cilacap****
              </small>
            </div>
          </td>
          <td class="text-center"><small>KM</small></td>
          <td class="text-center">196.49</td>
          <td class="floatright">12,272,376,000</td>
          <td class="text-center">202.39</td>
          <td class="floatright">14,495,556,000</td>
          <td class="text-center">198.24</td>
          <td class="floatright">20,312,422,000</td>
          <td class="text-center">198.24</td>
          <td class="floatright">47,080,354,000</td>
        </tr>


        <tr>
          <td>
            <div>
              <small class="ml-1"> ..........</small>
            </div>

          </td>
          <td class="text-center"><small>...</small></td>
          <td class="text-center">...</td>
          <td class="floatright">...</td>
          <td class="text-center">...</td>
          <td class="floatright">...</td>
          <td class="text-center">...</td>
          <td class="floatright">...</td>
          <td class="text-center">... </td>
          <td class="floatright">... </td>
        </tr>
      </table>




      <!-- <b-row>
        <b-col md="1">Kode</b-col>
        <b-col md="3">Uraian</b-col>
        <b-col md="1">Satuan</b-col>
        <b-col md='5'>
          <b-row class="centerText">
            <b-col md="12"><strong>Capaian Kinerja Program dan<br> Kerangka Pendanaan</strong></b-col>
          </b-row>
          <b-row class="centerText">
            <b-col md="4">
              <b-row>
                <b-col md="12"><b>2024</b></b-col>
              </b-row>
              <b-row>
                <b-col md="6">Target</b-col>
                <b-col md="6">Rp.</b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row>
                <b-col md="12"><b>2025</b></b-col>
              </b-row>
              <b-row>
                <b-col md="6">Target</b-col>
                <b-col md="6">Rp.</b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row>
                <b-col md="12"><b>2026</b></b-col>
              </b-row>
              <b-row>
                <b-col md="6">Target</b-col>
                <b-col md="6">Rp.</b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md='2'>
          <b-row class="centerText">
            <b-col md="12"><small>Kondisi Kinerja Pada Akhir Periode RPD</small></b-col>
          </b-row>
          <b-row>
            <b-col md="12"><b>2026</b></b-col>
          </b-row>
          <b-row>
            <b-col md="6">Target</b-col>
            <b-col md="6">Rp.</b-col>
          </b-row>
        </b-col>
      </b-row> -->
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol, BAlert,
  BCard, BCardBody
} from 'bootstrap-vue'
export default {
  components: {
    BBadge,
    BRow,
    BCol, BAlert, BCard, BCardBody,
  }

}
</script>

<style>
.centerText {
  text-align: center
}

.floatright {
  text-align: right;
}
</style>